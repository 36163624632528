/* eslint-disable no-restricted-globals */
<template>
  <b-sidebar
    id="add-new-event-sidebar"
    :visible="isAddNewEventSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm(); $refs.refFormObserver.reset()"
    @change="(val) => $emit('update:is-add-new-event-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar evento
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Title -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Título"
              label-for="title"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="name"
                v-model="form.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Descrição do Evento"
              label-for="description"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                placeholder="Digite a descrição do congresso"
                rows="3"
                max-rows="6"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            v-slot="{ errors }"
            name="type"
            rules="required"
          >
            <b-form-group
              label="Tipo"
              label-for="type"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="type"
                v-model="form.type"
                :options="[{text:'Palestra',value:'1'},{text:'Mesa Redonda', value: '2'}]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Theme -->
          <validation-provider
            v-slot="{ errors }"
            name="profession"
            rules="required"
          >
            <b-form-group
              label="Tema"
              label-for="theme"
              :state="errors.theme > 0 ? false : null"
            >
              <b-form-input
                id="theme"
                v-model="form.theme"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Date -->
          <validation-provider
            v-slot="{ errors }"
            name="date"
          >
            <b-form-group
              label="Data"
              label-for="date"
              :state="errors.length > 0 ? false : null"
            >
              <datepicker
                id="date"
                v-model="form.date"
                input-class="form-control"
                :language="ptBR"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col>
              <!--  Start Time -->
              <validation-provider
                v-slot="{ errors }"
                ref="start_time"
                name="start_time"
                rules="required"
              >
                <b-form-group
                  label="Hora Início"
                  label-for="start_time"
                  :state="errors.length > 0 ? false : null"
                >
                  <vue-timepicker
                    id="start_time"
                    ref="start_time"
                    v-model="form.start_time"
                    input-class="form-control"
                    close-on-complete
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <!-- End Time -->
              <validation-provider
                v-slot="{ errors }"
                name="end_time"
                rules="required|time_new_gt:start_time"
              >
                <b-form-group
                  label="Hora Fim"
                  label-for="end_time"
                  :state="errors.length > 0 ? false : null"
                >
                  <vue-timepicker
                    id="end_time"
                    v-model="form.end_time"
                    input-class="form-control"
                    close-on-complete
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Congress -->
          <validation-provider
            v-slot="{ errors }"
            name="congress"
            rules="required"
          >
            <b-form-group
              label="Congresso"
              label-for="congress"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="congress"
                v-model="form.congress"
                :options="congress"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
                :disabled="true"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- LINK url Webcam -->
          <!-- <validation-provider
            v-slot="{ errors }"
            name="link2"
          >
            <b-form-group
              label="URL Webcam link"
              label-for="link2"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="link2"
                v-model="form.link2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider> -->

          <!-- LINK url Streamer -->
          <validation-provider
            v-slot="{ errors }"
            name="link"
          >
            <b-form-group
              label="URL link"
              label-for="link"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="link"
                v-model="form.link"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- LINK type -->
          <validation-provider
            v-slot="{ errors }"
            name="type_link"
          >
            <b-form-group
              label="Streamer"
              label-for="type_link"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="congress"
                v-model="form.type_link"
                :options="streamer"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Room -->
          <validation-provider
            v-slot="{ errors }"
            name="room"
            rules="required"
          >
            <b-form-group
              label="Sala"
              label-for="rooms"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="room"
                v-model="form.room"
                :options="rooms"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- READY TO START -->
          <validation-provider
            v-slot="{ errors }"
            name="ready"
            rules="required"
          >
            <b-form-group
              label="Pronto para iniciar"
              label-for="ready"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="ready"
                v-model="form.ready"
                :options="ready"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Active -->
          <validation-provider
            v-slot="{ errors }"
            name="active"
            rules="required"
          >
            <b-form-group
              label="Ativado"
              label-for="active"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="active"
                v-model="form.active"
                :options="[{text:'Sim',value:1},{text:'Não', value: 0}]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Adicionar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker/src'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  required, min_value as minValue, email, integer, required_if, min, confirmed
} from 'vee-validate/dist/rules'
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import eventsService from './eventsService'
import vSelect from 'vue-select'

extend('confirmed', {
  ...confirmed,
  message: 'Senha de confirmação diferente da senha.',
})

extend('min', {
  ...min,
  message: 'Mínimo de caracteres são 6.',
})

extend('required_if', {
  ...required_if,
  message: 'Esse campo é obrigatório.',
})

extend('integer', {
  ...integer,
  message: 'Esse aceita apenas números.',
})

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('email', {
  ...email,
  message: 'Digite um email válido.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Datepicker,
    VueTimepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewEventSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      ptBR,
      congress: [],
      roomsSelect: ['Sala 01', 'Sala 02'],
      streamer: [
        {
          text: 'youtube',
          value: 'youtube'
        },
        {
          text: 'cloudflare',
          value: 'cloudflare'
        },
        {
          text: 'vimeo',
          value: 'vimeo'
        }
      ],
      ready: [
        {
          text: 'Sim',
          value: 1,
        },
        {
          text: 'Não',
          value: 0
        }
      ],
      rooms: [
        {
          text: 'Sala 01',
          value: 1
        },
        {
          text: 'Sala 02',
          value: 2
        },
      ],
      form: {
        title: null,
        description: null,
        theme: null,
        type: null,
        date: null,
        start_time: null,
        end_time: null,
        congress: null,
        room: null,
        active: null,
        link2: null,
        link: null,
        ready: null,
        type_link: null,
      },
    }
  },
  computed: {
    congressState() {
      return this.$store.state.congressAdministrator.congress
    },
    roomsState() {
      return this.$store.state.congressAdministrator.rooms
    }
  },
  watch: {
    congressState() {
      this.congress = this.congressState.map((congress) => ({
        text: congress.title,
        value: congress.id,
      }))

      // eslint-disable-next-line radix
      this.form.congress = this.congress.filter((congress) => congress.value === parseInt(this.$route.params.congress_id))
    },
    isAddNewEventSidebarActive() {
      // eslint-disable-next-line radix
      this.form.congress = this.congress.filter((congress) => congress.value === parseInt(this.$route.params.congress_id))
    }
  },
  created() {
    extend('time_new_gt', {
      validate: this.validateTimeNew,
      message: 'Hora Fim deve ser maior que Hora Início',
    })
    this.ActionListAllCongress()
    this.ActionListAllRooms()
  },
  mounted() {
    // eslint-disable-next-line radix
    this.form.congress = this.congress.filter((congress) => congress.value === parseInt(this.$route.params.congress_id))
  },
  methods: {
    ...mapActions('congressAdministrator', [
      'ActionListAllCongress',
      'ActionListAllRooms',
    ]),
    validateTimeNew(value, start_time) {
      if (!this.isBusy) {
        if (Number.isNaN(this.$data.form[start_time].HH) || Number.isNaN(value.HH)) {
          return false
        }
        const endTime = this.$moment(`${value.HH}:${value.mm}`, 'HH:mm a')
        const startTime = this.$moment(`${this.$data.form[start_time].HH}:${this.$data.form[start_time].mm}`, 'HH:mm a')

        return endTime.isAfter(startTime)
      }
      return true
    },
    resetForm() {
      this.form = {
        title: null,
        description: null,
        theme: null,
        type: null,
        date: null,
        start_time: null,
        end_time: null,
        congress: null,
        room: null,
        active: null,
        link2: null,
        link: null,
        ready: null,
        type_link: null,
      }
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            this.isBusy = true
            if (this.form.date) {
              this.form.date = this.$moment(this.form.date).format('YYYY-MM-DD')
            }

            eventsService().sendEvent({
              title: this.form.title,
              description: this.form.description,
              theme: this.form.theme,
              type: this.form.type,
              date: this.form.date,
              start_time: `${this.form.start_time.HH}:${this.form.start_time.mm}`,
              end_time: `${this.form.end_time.HH}:${this.form.end_time.mm}:00`,
              congress: this.form.congress[0].value,
              room: this.form.room,
              active: this.form.active,
              link2: this.form.link2,
              link: this.form.link,
              ready: this.form.ready,
              type_link: this.form.type_link,
            }).then((response) => {
              console.log(response)
              if (response.status === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: response.message,
                    text: ` ERROR: ${response.errors[0][0]} #${response.errors[0][1]}`,
                    variant: 'danger',
                  }
                })
              } else if (response.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
              }
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: err.message,
                  variant: 'danger',
                }
              })
            })
              .finally(() => { this.isBusy = false })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-congress-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
